import React, { useState, useEffect } from 'react';
import { serverIP, websiteIP } from "../index";
import '../pages/add_event.css';
import SimpleModal from './SimpleModal';
import emailjs from '@emailjs/browser';
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "reactstrap";
import { config } from "../index";
import { colors } from '@mui/material';

const Add_event = () => {
    const {
        user,
        isAuthenticated,
        getAccessTokenSilently
    } = useAuth0();

    const [omschrijving, setOmschrijving] = useState("");
    const maxChars = 255;

    const getTodayDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
    };

    const [startDatum, setStartDatum] = useState(getTodayDate);
    const [eindDatum, setEindDatum] = useState(getTodayDate);
    const [dateError, setDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [fileError, setFileError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [organisator, setOrganisator] = useState(user.name);
    const [doelError, setDoelError] = useState(false);
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [disableEmailInput, setDisableEmailInput] = useState(true);
    const [formData, setFormData] = useState({
        organisator: '',
        eventNaam: '',
        startDatum: '',
        eindDatum: '',
        doel: '',
        omschrijving: '',
        persoonlijkeReden: ''
    });

    useEffect(() => {
        if (user?.email) {
            setEmail(user.email);
            setEmailValid(true);
            setDisableEmailInput(true);
        } else {
            setEmail('');
            setEmailValid(false);
            setDisableEmailInput(false);
        }
    }, [user]);

    const handleOmschrijvingChange = (e) => {
        setOmschrijving(e.target.value);
    };

    const handleStartDateChange = (event) => {
        const selectedDate = new Date(event.target.value);
        const today = new Date(getTodayDate());
        const stateEndDate = new Date(eindDatum);

        if (selectedDate < today) {
            setStartDatum(getTodayDate());
            setDateError(true);
        } else {
            setStartDatum(event.target.value);
            setDateError(false);
            if (stateEndDate < selectedDate) {
                setEindDatum(event.target.value);
            }
        }
    };

    const handleEndDateChange = (event) => {
        const selectedEndDate = new Date(event.target.value);
        const stateStartDate = new Date(startDatum);

        if (selectedEndDate < stateStartDate) {
            setEndDateError(true);
            setEindDatum(startDatum);
        } else {
            setEindDatum(event.target.value);
            setEndDateError(false);
        }
    };

    const handleFileChange = (event) => {
        if (event.target.files.length > 3) {
            setFileError(true);
            event.target.value = '';
        } else {
            setFileError(false);
        }
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmailChange = (e) => {
        const inputEmail = e.target.value;
        setEmail(inputEmail);
        setEmailValid(validateEmail(inputEmail));
    };

    const resetForm = () => {
        document.getElementById("organisator").value = '';
        document.getElementById("eventNaam").value = '';
        document.getElementById("doel").value = '';
        document.getElementById("omschrijving").value = '';
        document.getElementById("afbeeldingen").value = '';
    };

    const sendMail = async (ontvanger, id) => {
        const parameters = {
            "ontvanger":ontvanger,
            "url":window.location.protocol+"//"+websiteIP+"/#/detail/"+id,
            "email": user.email
        }
        //console.log("email verzonden naar: ", user.email);
        emailjs.send(config.emailjs_serviceID,config.emailjs_temlapteID_add_event,parameters, {
            publicKey: config.emailjs_publicKey,
        }).then(
            (response) => {
                //console.log('succes', response.status, response.text)
            },
            (error) => {
                console.log("Failed:", error)
            }
        )
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        const userSub = user && user.sub;

        const files = document.querySelector('#afbeeldingen').files;
        let filenames = [];
        for (let file of files) {
            filenames.push(file.name);
            formData.append('image', file, file.name);
        }

        try {
            const token = await getAccessTokenSilently();
            const nieuw = {
                'organisator': e.target.organisator.value.trim(),
                'eventNaam': e.target.eventNaam.value.trim(),
                'startDatum': e.target.startDatum.value.trim(),
                'eindDatum': e.target.eindDatum.value.trim(),
                'doel': e.target.doel.value.trim(),
                'omschrijving': e.target.omschrijving.value.trim(),
                'fotoPath': filenames,
                'userSub': userSub
            };

            const ans = await fetch(`${window.location.protocol}//${serverIP}/events`, {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`
                },
                body: JSON.stringify(nieuw)
            });

            if (ans.status === 201) {
                const id = ans.headers.get("Location").split("/")[4];
                const ansImage = await fetch(`${window.location.protocol}//${serverIP}/events/files/${id}`, {
                    method: 'post',
                    body: formData,
                    headers: { authorization: `Bearer ${token}` }
                });

                if (ansImage.status !== 201) {
                    await fetch(`${window.location.protocol}//${serverIP}/events/${id}`, {
                        method: 'delete',
                        headers: { authorization: `Bearer ${token}` }
                    });
                    alert("Something went wrong while uploading images.");
                } else {
                    resetForm();
                    setShowModal(false);
                    setConfirmationModal(true);
                    sendMail(e.target.organisator.value, id);
                }
            } else {
                alert("Something went wrong while creating the event.");
            }
        } catch (error) {
            console.error("Error during fetch:", error);
            alert("Failed to fetch. Please check your network connection and try again.");
        }
    };

    const checkNumber = (e) => {
        const input = e.target.value;
        if (!/^[1-9]+$/.test(input)) {
            setDoelError(true);
        }
        setDoelError(false);
    };

    if (!isAuthenticated) {
        return <div> Redirecting... </div>;
    }

    const handleOpenModal = (e) => {
        e.preventDefault();

        const form = e.target;
        const data = {
            organisator: form.organisator.value.trim(),
            eventNaam: form.eventNaam.value.trim(),
            startDatum: form.startDatum.value.trim(),
            eindDatum: form.eindDatum.value.trim(),
            doel: form.doel.value.trim(),
            omschrijving: form.omschrijving.value.trim()
        };

        setFormData(data);
        setShowModal(true);
    };

    const handleConfirmSubmit = async () => {
        const form = document.querySelector(".form-container");
        await handleSubmit({ preventDefault: () => { }, target: form });
    };

    return (
        <div className="metadata-form">
            <SimpleModal
                isOpen={showModal}
                close={() => setShowModal(false)}
            >
                <h2>Bevestig uw gegevens</h2>
                <p><strong>Organisator:</strong> {formData.organisator}</p>
                <p><strong>Event Naam:</strong> {formData.eventNaam}</p>
                <p><strong>Startdatum:</strong> {formData.startDatum}</p>
                <p><strong>Einddatum:</strong> {formData.eindDatum}</p>
                <p><strong>Doelbedrag:</strong> € {formData.doel}</p>
                <p><strong>Omschrijving:</strong> {formData.omschrijving}</p>
                <div className="unique-container">
                    <div className="button-container">
                        <Button className="verzendButton" onClick={() => setShowModal(false)}>Pas aan</Button>
                        <Button onClick={handleConfirmSubmit}>Verzend</Button>
                    </div>
                </div>
            </SimpleModal>
            <SimpleModal
                isOpen={confirmationModal}
                close={() => setConfirmationModal(false)}
            >
                <h2>Je event is ingediend en wordt beoordeeld door een admin van de Hersenletsel Liga. Er is een bevestigingsmail naar je adres gestuurd; controleer ook je spamfolder.</h2>
                <div className="button-container">
                    <Button onClick={() => setConfirmationModal(false)}>OK</Button>
                </div>
            </SimpleModal>
            <header className="Add-event-header">
                <h1 className='Organisatietitel'>Event<span className="orange-letter">Organiseren</span></h1>
            </header>
            <form onSubmit={handleOpenModal} className="form-container">
                <div className="form-group">
                    <label htmlFor="organisator">Organisator *</label>
                    <i className='text-org'>De naam die u ingeeft als organisator is publiek zichtbaar op het event.</i>
                    <input
                        type="text"
                        name="organisator"
                        id="organisator"
                        value={organisator}
                        onChange={(e) => setOrganisator(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email *</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        onChange={handleEmailChange}
                        required
                        disabled={disableEmailInput}
                    />
                    {!emailValid && <div className="error">Geef een geldig email adres in.</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="eventNaam">Event Naam*</label>
                    <input type="text" name="eventNaam" id="eventNaam" required />
                </div>
                <div className="form-group">
                    <label htmlFor="startDatum">Startdatum *</label>
                    <input
                        type="date"
                        name="startDatum"
                        id="startDatum"
                        value={startDatum}
                        min={getTodayDate()}
                        onChange={handleStartDateChange}
                        required
                    />
                    {dateError && <div className="error">Startdatum moet in het heden zijn.</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="eindDatum">Einddatum *</label>
                    <input type="date" name="eindDatum" id="eindDatum" value={eindDatum}
                        min={startDatum}
                        onChange={handleEndDateChange}
                        required
                    />
                    {endDateError && <div className="error">Einddatum kan niet voor begindatum zijn.</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="doel">Doelbedrag *</label>
                    <input type="number" name="doel" id="doel" onChange={checkNumber} required />
                    {doelError &&
                        <div className="error">Het doel bedrag moet een positief getal zijn zonder komma.</div>}
                </div>
                <div className="form-group">
                    <div className='omschrijving-header'>
                        <label htmlFor="omschrijving">Omschrijving *</label>
                        <div className="char-count-inside">
                            {omschrijving.length}/{maxChars}
                        </div>
                    </div>
                        <textarea
                            name="omschrijving"
                            id="omschrijving"
                            maxLength={maxChars}
                            value={omschrijving}
                            onChange={handleOmschrijvingChange}
                            required
                        />
                    </div>
                <div className="form-group">
                    <label htmlFor="afbeeldingen">Afbeeldingen (max 3)*</label>
                    <input
                        required
                        type="file"
                        name="afbeeldingen"
                        id="afbeeldingen"
                        accept="image/*"
                        multiple
                        onChange={handleFileChange}
                    />
                    {fileError && <div className="error">Maximum 3 afbeeldingen. *</div>}
                </div>
                <div className="button-container">
                    <button type="submit">Verzend</button>
                </div>
                <p className="verzendinfo">De ingevulde gegevens kunnen later niet aangepast worden</p>
            </form>
        </div>
    );
};
export default Add_event;
