import React, { useState } from 'react';
import { Link, Outlet, NavLink } from "react-router-dom";
import logo from '../img/HLL-logo-1-RGB-.png';
import './Layout.css';
import Account from './Account';
import Footer from './Footer'; // Import the Footer component

const Layout = () => {
    const [isNavVisible, setIsNavVisible] = useState(false);

    const toggleNav = () => {
        setIsNavVisible(!isNavVisible);
    };

    const handleNavLinkClick = () => {
        setIsNavVisible(false); // Hide the navigation menu when a link is clicked
    };

    return (
        <div className="site-container"> {/* Wrap everything in a flex container */}
            <header className="header">
                <div className="logo-container">
                    <Link to="/">
                        <img className="logo" src={logo} alt="logo" />
                    </Link>
                </div>
                <button className="nav-toggle" onClick={toggleNav} aria-label="toggle navigation">
                    <span className="hamburger"></span>
                </button>
                <nav className={`navigation ${isNavVisible ? 'nav-visible' : ''}`}>
                    <ul className="nav-list">
                        <li className="nav-item">
                            <NavLink exact to="/" className="nav-link" activeClassName="active" onClick={handleNavLinkClick}>Home</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/events" className="nav-link" activeClassName="active" onClick={handleNavLinkClick}>Sponsor Een Event</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/doneren" className="nav-link" activeClassName="active" onClick={handleNavLinkClick}>Doe Een Gift</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/add_event" className="nav-link" activeClassName="active" onClick={handleNavLinkClick}>Event Aanmaken</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/faq" className="nav-link" activeClassName="active" onClick={handleNavLinkClick}>Hoe Het Werkt</NavLink>
                        </li>
                        <li className="nav-item">
                            <Link to="https://www.hersenletselliga.be" className="nav-link" activeClassName="active" target='_blank' onClick={handleNavLinkClick}>Meer info over ons</Link>
                        </li>
                        <li>
                            <Account handleNavLinkClick={handleNavLinkClick}/>
                        </li>
                    </ul>
                </nav>
            </header>
            <Outlet /> {/* This is where the page content will be rendered */}
            <Footer />  {/* Footer is always at the bottom */}
        </div>
    );
};

export default Layout;
