import React from 'react';
import '../pages/faq.css';
import faq4 from '../img/site gift event.jpg';
import faq5 from '../img/faq5.jpg';
import { Link, Link as RouterLink } from 'react-router-dom';

function Faq() {
    return (
        <div className='body'>
            <div className="slide-container-1">
                {/* <img src={faq5} class="header-image-1" alt="Header Image" /> */}
                <div className='header-text'>
                    <h2 class="header-title"><strong>Hoe de fondsenwerving van de Hersenletsel Liga werkt</strong></h2>
                    <p>De giftensite van de Hersenletsel Liga is de perfecte plek om geld in te zamelen, of je nu alleen bent, een groep of een organisatie.</p>
                    <p>Wil je ons helpen om mensen met een hersenletsel te ondersteunen? </p>
                    <p>Zet je eigen actie op en zamel geld in voor de Hersenletsel Liga!</p>

                </div>

                {/* <div class="vertical-border"></div> */}
                {/* <div class="header-image-container">
                    
                </div> */}
            </div>
            <div className='horizontal-border'></div>
            <div className="slide-container">
                <div class="header-image-container">
                    <img src={faq4} class="header-image" alt="Header Image" />
                </div>

                <div class="vertical-border"></div>

                <div className="bullet-text">

                    <div class="bullet-impact-item">
                        <h3>Ideetje voor een event</h3>
                        <ul>
                            <li>Een (huwelijks)verjaardag</li>
                            <li>Sporten voor de Hersenletsel Liga</li>
                            <li>Een spontane inzamelactie</li>
                            <li>Zomaar</li>
                        </ul>

                    </div>
                    <div class="bullet-impact-item">
                        <h3>Lanceer je event</h3>
                        <ul>
                            <li>Ga naar “Event Aanmaken”</li>
                            <li>Maak je event aan</li>
                            <li>Stel je doelbedrag in</li>
                            <li>Voeg een foto toe</li>
                        </ul>
                    </div>
                    <div class="bullet-impact-item">
                        <h3>Verspreid het woord</h3>
                        <ul>
                            <li>Stuur e-mails</li>
                            <li>Stuur sms-berichten</li>
                            <li>Deel via vrienden en familie</li>
                            <li>Deel op sociale media</li>
                        </ul>
                    </div>
                    <div class="bullet-impact-item">
                        <h3>Beheer je event</h3>
                        <ul>
                            <li>Hou je event in de gaten</li>
                            <li>Bepaal het te behalen bedrag</li>
                        </ul>
                    </div>

                </div>
            </div>

            <div className="slide-container">
                <div className='faq-text'>
                    <h2 class="header-title"><strong>Frequently Asked Questions</strong></h2>
                    <ul>
                        <li>
                            <strong>Welk type events kan ik aanmaken?</strong>
                            <p>Doe je mee aan een event, en je wil je laten sponsoren ten gunste van de Hersenletsel Liga, dan ben je op deze site op de juiste plaats. Maak je event aan en nodig al je kennissen uit je te sponsoren voor het goede doel.
                                Heb je besloten om een inzamelactie te starten voor het goede doel, dan ben je hier ook op het goede adres: het kan ter gelegenheid van je verjaardag, een jubeleum, een speciale datum, ter nagedachtenis van een dierbare, … of gewoon zomaar. Maak je event aan en jij en je kenissen steunen de Hersenletsel Liga.
                            </p>
                        </li>
                        <li>
                            <strong>Hoe maak ik een event aan?</strong>
                            <p>Wil je een event organiseren voor de Hersenletsel Liga,
                                Ga naar onze “Event Aanmaken” tab op deze site.
                                Daar kan je kiezen om een event te organiseren en geef je alle nodige details.
                                Stel je doelbedrag in, je motivatie en de start- en einddatum van het project.
                                Je kan ook een leuke foto toevoegen. Nadat je je event hebt aangemaakt, keurt
                                de administrator van de website het nog goed, en kan je beginnen geld inzamelen.</p>
                        </li>
                        <li>
                            <strong>Waar gaat het bedrag naartoe?</strong>
                            <p>De ingezamelde giften gaan rechtstreeks naar de Hersenletsel Liga. Wij gebruiken het ingezamelde bedrag uitsluitend om personen met een hersenletsel en hun naasten te helpen.</p>
                        </li>
                        <li>
                            <strong>Moet ik mijn rijksregisternummer ingeven als ik een donatie doe?</strong>
                            <p>Neen, je moet enkel je rijksregisternummer ingeven indien je een fiscaal attest wenst te verkrijgen van de Hersenletsel Liga. Alle giften boven de 40 euro per jaar komen in aanmerking voor een fiscaal attest. Tot verleden jaar was dit niet nodig, maar een recente wetswijziging heeft deze extra vereiste opgelegd.</p>
                        </li>
                        <li>
                            <strong>Hoe deel ik makkelijk mijn event?</strong>
                            <p>Elk event heeft een link om je initiatief te delen met je omgeving.
                                Er is een optie om rechtstreeks te delen op Facebook en Whatapp.
                                Je kan ook de link naar je initiatief kopiëren en delen naar iedereen die je het wil laten weten via
                                mail, sms, sociale media...</p>
                        </li>
                        <li>
                            <strong>Wat gebeurt er als we het doelbedrag niet halen?</strong>
                            <p>Het doelbedrag is een uitdaging die je jezelf zet. Zelfs indien het doelbedrag niet wordt gehaald,
                                wordt het ingezamelde geld nog steeds gebruikt om personen met een hersenletsel en hun omgeving te helpen.
                                We moedigen organisatoren aan om transparant te zijn over hoe elk bedrag, groot of klein, zal worden gebruikt om een verschil te maken.</p>
                        </li>
                        <li>
                            <strong>Ik doneerde aan een verwijderd evenement, waar gaat mijn donatie naartoe? </strong>
                            <p>Het geld gaat nooit verloren. Alle giften gaan rechtstreeks naar de Liga (en dus niet naar de organisator). 
                                De Liga gebruikt dat geld om personen met een NAH te helpen. Dus als het evenement van de website verdwijnt, 
                                is de gift aan de Liga al gedaan, ook al is het evenement geannuleerd. 
                            </p>
                        </li>
                    </ul>
                    <div className="contact-us-container">
                        Andere vragen? 
                        <a href='https://hersenletselliga.be/contact/' target='_blank<'>Contacteer ons</a>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Faq;
