import React, { useState, useEffect } from 'react';
import { serverIP } from "../index";
import { useAuth0 } from "@auth0/auth0-react";
import './EditEventForm.css'

const EditEventForm = ({ event, onClose }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [formData, setFormData] = useState({ ...event });
    const [dateError, setDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [omschrijving, setOmschrijving] = useState("");
    const maxChars = 255;

    useEffect(() => {
        setFormData({ ...event });
    }, [event]);

    const handleStartDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        const stateEndDate = new Date(formData.eindDatum);

        if (selectedDate < new Date()) {
            setFormData({ ...formData, startDatum: event.startDatum });
            setDateError(true);
        } else {
            setFormData({ ...formData, startDatum: e.target.value });
            setDateError(false);
            if (stateEndDate < selectedDate) {
                setFormData({ ...formData, eindDatum: e.target.value });
            }
        }
    };

    const handleOmschrijvingChange = (e) => {
        const omschrijvingText = e.target.value;  // Haal de tekst uit de textarea
        setFormData({ ...formData, omschrijving: omschrijvingText });
    };

    const handleEndDateChange = (e) => {
        const selectedEndDate = new Date(e.target.value);
        const stateStartDate = new Date(formData.startDatum);

        if (selectedEndDate < stateStartDate) {
            setEndDateError(true);
            setFormData({ ...formData, eindDatum: event.eindDatum });
        } else {
            setFormData({ ...formData, eindDatum: e.target.value });
            setEndDateError(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            //console.log("voor AccessTokenSilently()");
            const token = await getAccessTokenSilently();
            //console.log("na AccessTokenSilently()");
            const response = await fetch(`${window.location.protocol}//${serverIP}/events/${event.id}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ ...formData, accepted: false }), // mark as pending
            });

            //console.log("this is the response: ", response);

            if (response.ok) {
                alert('Event changes submitted for admin approval!');
                onClose();
            } else {
                alert('Failed to submit event changes.');
            }
        } catch (error) {
            console.error("Error updating event:", error);
            alert('An error occurred while submitting event changes.');
        }
    };

    return (
        <div className="edit-event-form">
            <h2>Edit Event</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="eventNaam">Event Naam*</label>
                    <input
                        type="text"
                        name="eventNaam"
                        value={formData.eventNaam}
                        onChange={(e) => setFormData({ ...formData, eventNaam: e.target.value })}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="startDatum">Startdatum *</label>
                    <input
                        type="date"
                        name="startDatum"
                        value={formData.startDatum}
                        onChange={handleStartDateChange}
                        required
                    />
                    {dateError && <div className="error">Startdatum moet in het heden zijn.</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="eindDatum">Einddatum *</label>
                    <input
                        type="date"
                        name="eindDatum"
                        value={formData.eindDatum}
                        onChange={handleEndDateChange}
                        required
                    />
                    {endDateError && <div className="error">Einddatum kan niet voor begindatum zijn.</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="doel">Doelbedrag *</label>
                    <input
                        type="number"
                        name="doel"
                        value={formData.doel}
                        onChange={(e) => setFormData({ ...formData, doel: e.target.value })}
                        required
                    />
                </div>
                <div className="form-group">
                    <div className='omschrijving-header'>
                        <label htmlFor="omschrijving">Omschrijving *</label>
                        <div className="char-count-inside">
                            {formData.omschrijving.length}/{maxChars}
                        </div>
                    </div>
                        <textarea
                            name="omschrijving"
                            id="omschrijving"
                            maxLength={maxChars}
                            value={formData.omschrijving}
                            onChange={(e) => handleOmschrijvingChange(e)}
                            required
                        />
                    </div>

                <button type="submit">Submit for Approval</button>
            </form>
        </div>
    );
};

export default EditEventForm;
